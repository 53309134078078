const skills = [
    {
        name: 'Angular',
        level: 90
    },
    {
        name: 'React',
        level: 75
    },
    {
        name: 'Redux',
        level: 75
    },
    {
        name: 'NodeJS',
        level: 90
    },
    {
        name: 'ExpressJS',
        level: 90
    },
    {
        name: 'MongoDB',
        level: 60
    },
    {
        name: 'MYSQL',
        level: 80
    },
    {
        name: 'Python',
        level: 90
    },
    {
        name: 'Flask',
        level: 80
    },
    {
        name: 'Material',
        level: 90
    },
    {
        name: 'Git',
        level: 90
    },
    {
        name: 'MatplotLib',
        level: 70
    },
    {
        name: 'Docker',
        level: 90
    },
    {
        name: 'Kubernetes',
        level: 85
    },
    {
        name: 'Pandas',
        level: 70
    },
    {
        name: 'JS',
        level: 90
    },
    {
        name: 'CSS',
        level: 90
    },
    {
        name: 'HTML',
        level: 90
    },
    {
        name: 'JENKINS',
        level: 50
    }
];

export default skills;