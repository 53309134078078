import React, { useState } from 'react';
import './App.scss';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme/theme';
import { GlobalStyles } from './theme/global';
import HeaderComponent from './components/header.component';
import Brightness4Rounded from '@material-ui/icons/Brightness4Rounded';
import Brightness7 from '@material-ui/icons/Brightness7';
import { Paper, makeStyles } from '@material-ui/core';
import BootstrapTooltip from './common/bootstraptooltip.common';
import IntroductionComponent from './components/introduction.component';
import HrComponent from './common/hr.component';
import SkillsComponent from './components/skills.component';
import ExperienceComponent from './components/experience.component';
import EducationComponent from './components/education.component';
import FooterComponent from './components/footer.component';
import SideNavComponent from './components/sidenav.component';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import AchievementsComponent from './components/achievements.component';
import OpenSourceComponent from './components/open_source.component';
import ResearchPaperComponent from './components/research_paper.component';
import ProjectsComponent from './components/projects.component';

import Navs from './data/nav.data';
import * as $ from 'jquery';

const AppStyles = makeStyles({
  toggler: {
    opacity: 0.7,
    '&:hover': {
      opacity: 1
    }
  },
});

function App() {
  const classes = AppStyles();
  const stored_theme = window.localStorage.getItem('website_theme');
  const [theme, setTheme] = useState(stored_theme ? stored_theme : 'light');
  const [activeNav, setNav] = useState('me');
  const toggleTheme = () => {
    if (theme === 'light') {
      window.localStorage.setItem('website_theme', 'dark');
      setTheme('dark');
    } else {
      window.localStorage.setItem('website_theme', 'light');
      setTheme('light');
    }
  }

  window.addEventListener('scroll', (event) => {
    const automatedScroll = window.localStorage.getItem("automated");
    if (!automatedScroll || automatedScroll === 'false') {
      const currentScrollPos = $(document).scrollTop();
      Navs.forEach(nav => {
        const refElem = $(`#${nav.link}`);
        if (refElem.position().top <= currentScrollPos + 250 && refElem.position().top + refElem.height() > currentScrollPos + 250) {
          setNav(nav.link);
        }
      });
    }
  });

  const onnavchange = (navlink) => {
    window.localStorage.setItem('automated', "true");
    const element = document.getElementById(navlink);
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    setNav(navlink);
    setTimeout(() => {
      window.localStorage.setItem('automated', "false");
    }, 1000);
  }

  const goToTop = () => {
    const element = document.getElementById('root');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    setNav('me');
  }

  return <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />
    <Paper className={classes.toggler} elevation={6} onClick={toggleTheme} style={{ display: 'inline-block', position: 'fixed', right: '50px', top: '-5px', padding: '5px', zIndex: 1000 }}>
      <BootstrapTooltip title='Go Dark'>
        <Brightness4Rounded style={{ display: theme === 'light' ? 'inline-block' : 'none' }} />
      </BootstrapTooltip>
      <BootstrapTooltip title='Morning Sunshine'>
        <Brightness7 style={{ display: theme === 'dark' ? 'inline-block' : 'none' }} />
      </BootstrapTooltip>
    </Paper>
    <Paper elevation={3} style={{ display: 'inline-block', position: 'fixed', bottom: '20px', right: '50px', zIndex: 1000, color: theme === 'light' ? '#4b494c' : '#fff', backgroundColor: theme === 'dark' ? '#4b494c' : '#fff', borderRadius: '50%', width: '24px' }} onClick={goToTop}>
      <BootstrapTooltip title='Go To Top'>
        <ArrowUpIcon style={{ verticalAlign: "top" }} />
      </BootstrapTooltip>
    </Paper>
    <SideNavComponent selectedtheme={theme} onnavchange={onnavchange} activeNav={activeNav} />
    <HeaderComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <IntroductionComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <SkillsComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <EducationComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <ExperienceComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <AchievementsComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <OpenSourceComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <ResearchPaperComponent selectedtheme={theme} />
    <HrComponent selectedtheme={theme} width='10%' />
    <ProjectsComponent selectedtheme={theme} />
    <FooterComponent selectedtheme={theme}></FooterComponent>
  </ThemeProvider>
}

export default App;
