const osData = [
    {
        id: "os_1",
        name: "YAML Generator",
        date: "April, 2021",
        description: "This Open Source Project helps in generating ready to consume Kubernetes yamls. It takes a xml as input which offers user friendly inputs. It is a work in progress",
        link: "https://yaml-generator.herokuapp.com/"
    },
    {
        id: "os_2",
        name: "Angular D3 Graphs",
        date: "July, 2020",
        description: "This Open Source Library helps in plotting complex graphs/plots like confusion matrix, bar chart, density plots etc. with minimum possible code changes.",
        link: "https://www.npmjs.com/package/angular-d3-graphs"
    },
    {
        id: "os_3",
        name: "Angular Searchable List",
        date: "March, 2020",
        description: "This Open Source Library helps in creating the searchable list for Angular 2+ along with Angular Material.",
        link: "https://www.npmjs.com/package/angular-searchable-list"
    },
    {
        id: "os_4",
        name: "Ng Scroll Indicator",
        date: "August, 2020",
        description: "This Open Source Library helps in adding a horizontal scroll bar to the top of the webpage along with Angular 2+.",
        link: "https://www.npmjs.com/package/ng-scroll-indicator"
    }
];

export default osData;