const navs = [
    {
        id: "nav_1",
        name: "ME",
        link: "me"
    },
    {
        id: "nav_2",
        name: "SKILLS",
        link: "skills"
    },
    {
        id: "nav_3",
        name: "EDUCATION",
        link: "education"
    },
    {
        id: "nav_4",
        name: "EXPERIENCE",
        link: "experience"
    },
    {
        id: "nav_5",
        name: "CERTIFICATIONS & AWARDS",
        link: "certifications"
    },
    {
        id: "nav_6",
        name: "OPEN SOURCE CONTRIBUTIONS",
        link: "contributions"
    },
    {
        id: "nav_7",
        name: "Research Papers",
        link: "research"
    },
    {
        id: "nav_8",
        name: "Projects",
        link: "projects"
    }
]

export default navs;