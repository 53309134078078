import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";

const introductionStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "200px",
    padding: "10px",
    animation: "fadeInAnimation ease 2.5s",
    animationIterationCount: 1,
    animationFillMode: "forwards",
  },
  heading: {
    fontFamily: "OpenSansSemiBold",
    letterSpacing: "10px",
  },
  subheading: {
    fontFamily: "OpenSansBold",
    letterSpacing: "1px",
  },
  blockquote: {
    width: "98%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    fontFamily: "OpenSansRegular",
    marginTop: "1.5rem",
  },
}));

function IntroductionComponent(props) {
  const classes = introductionStyles();
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <div className="row" id="me">
        <Card className={classes.root} elevation={4}>
          <h4 className={classes.heading + " text-center mt-3"}>WHO AM I?</h4>
          <h6 className={classes.subheading + " text-center mt-3"}>
            {" "}
            OPEN SOURCE CONTRIBUTOR | M.E.A.N/M.E.R.N STACK DEVELOPER | UI/UX
            CONSULTANT | UDACITY CERTIFIED FRONT END ENGINEER | CNCF CKAD
          </h6>
          <blockquote className={classes.blockquote}>
            Specialist Programmer with over 4 years of experience in IT.
            Excellent reputation for resolving problems, improving customer
            satisfaction, and delivering optimum & scalable solutions.
            Consistently saved costs while increasing profits. Recognized
            consistently for performance excellence and contributions to success
            in the company. Strengths in M.E.A.N and M.E.R.N backed by training
            in Front End Technologies by Udacity. I am also a CNCF CKAD and have
            experience with K8s deployments, jenkins and Python.
          </blockquote>
        </Card>
      </div>
    </Grow>
  );
}

export default IntroductionComponent;
