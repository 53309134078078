const education = [
    {
        id: "edu_3",
        type: "Graduation",
        subtype: "B.Tech Hons.",
        score: "82.6%",
        stream: 'Computer Science and Engineering',
        institute: "University Institute of Information Technology, Summer Hill, H.P",
        startDate: "01 Aug, 2013",
        endDate: '01 Aug, 2017',
        location: 'Shimla',
        achievements: [
            "Gold Medalist, Awarded by President of India for scoring first rank in examination",
            "Technical Team Head: 2015 - 2017",
            "Placement Coordinator: 2016 - 2017",
            "Organized several technical events: 2015 - 2017"
        ]
    },
    {
        id: "edu_2",
        type: "Senior Secondary",
        stream: "PCMCs",
        institute: "Kendriya Vidyalaya, Jutogh Cantt, Shimla, H.P",
        startDate: "01 Mar, 2011",
        endDate: '01 Mar, 2013',
        location: 'Shimla',
        score: "94%",
        achievements: [
            "Served as Shivaji House Captain.",
            "Served as Vidyalaya Captain 2012 - 2013.",
            "Won cluster level Social Science Quiz Competition - 2012.",
            "Scored 2nd Place at Regional Level Social Science Quiz Competition - 2012.",
            "Organized various cluster and regional level competitions."
        ]
    },
    {
        id: "edu_1",
        type: "Secondary",
        institute: "Shishu Shiksha Niketan, Totu, Shimla, H.P",
        startDate: "01 Mar, 2010",
        endDate: '01 Mar, 2011',
        location: 'Shimla',
        score: "92.4",
        achievements: [
            "Scored 24th Rank state Rank in final examination",
            "Won several debate & quiz competitions across the district and state"
        ]
    }
]

export default education;