import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; import Grow from '@material-ui/core/Grow';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Education from '../data/education.data';
import StepButton from '@material-ui/core/StepButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WhatshotIcon from '@material-ui/icons/Whatshot';
function getSteps() {
    return [...Education];
}

const EducationStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px',
    },
    heading: {
        fontFamily: 'OpenSansSemiBold',
        letterSpacing: '10px'
    },
    subheading: {
        fontFamily: 'OpenSansBold',
        letterSpacing: '1px',
    },
    button: {
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    stepcompany: {
        fontFamily: 'OpenSansSemiBold'
    },
    stepdate: {
        fontFamily: 'OpenSansItalic',
        fontSize: '0.77rem'
    },
    cardtitle: {
        fontFamily: 'OpenSansSemiBold',
        fontSize: '1.1rem'
    },
    cardsubtitle: {
        fontFamily: 'OpenSansItalic',
        fontSize: '1rem'
    },
    cardsubtitle2: {
        fontFamily: 'OpenSansItalic',
        fontSize: '0.9rem'
    },
    cardsubtitle3: {
        fontFamily: 'OpenSansRegular',
        fontSize: '0.9rem',
        color: '#AAA',
        textTransform: 'uppercase'
    },
    carddescription: {
        fontFamily: "OpenSansRegular",
        marginTop: '0.5rem',
        fontStyle: "italic",
        fontWeight: 400,
        textAlign: 'justify',
    },
    hr: {
        border: '0.5px solid #adb5bd',
        marginTop: 0,
        marginBottom: 0,
    },
    chiptext: {
        opacity: 1,
        left: 0,
        transition: '0.6s',
        fontWeight: 500,
        fontSize: '0.6rem'
    },
    chip: {
        margin: theme.spacing(0.5),
        color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
    },
    stepbutton: {
        '&:active': {
            color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
            backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
        }
    },
    listtext: {
        fontFamily: "OpenSansLight"
    }
}))

function EducationComponent(props) {
    const classes = EducationStyles(props);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleStep = (step) => () => {
        if (activeStep === step) {
            setActiveStep(-1);
        } else {
            setActiveStep(step);
        }
    };

    function renderEducation(achievements) {
        const achievementArray = [];
        achievements.forEach(achievement => {
            achievementArray.push(
                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} key={achievement}>
                    <ListItemIcon style={{ minWidth: '30px' }}>
                        <WhatshotIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listtext}>
                        {achievement}
                    </ListItemText>
                </ListItem>,
            );
        });
        return achievementArray;
    }
    return (
        <Grow in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}>
            <div className='row' id="education">
                <Card className={classes.root} elevation={4}>
                    <h4 className={classes.heading + ' text-center mt-3'}>EDUCATION</h4>
                    <div className='col-12 col-lg-10 ml-auto mr-auto mt-3 mb-3 text-center'>
                        <Stepper nonLinear activeStep={activeStep} orientation='vertical' style={{ padding: '4px' }}>
                            {steps.map((edu, index) => {
                                const buttonProps = {};
                                return (
                                    <Step key={edu.id}>
                                        <StepButton
                                            onClick={handleStep(index)}
                                            {...buttonProps}
                                            icon={activeStep === index ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                        >
                                            <span className={classes.stepdate}>{edu.startDate} to {edu.endDate ? edu.endDate : 'Present'}</span>
                                        </StepButton>
                                        <StepContent>
                                            <div className="row">
                                                <Card className={'text-left mb-3 col-11 ml-auto mr-auto'} elevation={3}>
                                                    <CardContent>
                                                        <Typography variant='h5' className={classes.cardtitle} gutterBottom>
                                                            {edu.type} {edu.subtype && (
                                                                " - " + edu.subtype
                                                            )}
                                                        </Typography>
                                                        <hr align='left' className={classes.hr} />
                                                        <Typography variant='h6' component='h6' color='textSecondary' className={classes.cardsubtitle}>
                                                            {edu.institute}
                                                        </Typography>
                                                        {edu.stream && (
                                                            <Typography variant='subtitle1' component='h6' color='textSecondary' className={classes.cardsubtitle2 + " font-weight-bold"}>
                                                                Stream :  {edu.stream}
                                                            </Typography>
                                                        )}
                                                        <Typography variant='subtitle1' component='h6' color='textSecondary' className={classes.cardsubtitle2 + " font-weight-bold"}>
                                                            Scored : {edu.score}
                                                        </Typography>
                                                        <Typography variant='subtitle1' component='h6' color='textSecondary' className={classes.cardsubtitle3 + " font-weight-bold"}>
                                                            Achievements
                                                        </Typography>
                                                        <hr align='left' className={classes.hr} style={{}} />
                                                        <List dense={true}>
                                                            {renderEducation(edu.achievements)}
                                                        </List>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </StepContent>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>
                </Card>
            </div>
        </Grow>
    )
}

export default EducationComponent;