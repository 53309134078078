import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; import Grow from '@material-ui/core/Grow';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Experiences from '../data/experience.data';
import StepButton from '@material-ui/core/StepButton';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

function getSteps() {
    return [...Experiences];
}

const ExperienceStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px',
    },
    heading: {
        fontFamily: 'OpenSansSemiBold',
        letterSpacing: '10px'
    },
    subheading: {
        fontFamily: 'OpenSansBold',
        letterSpacing: '1px',
    },
    button: {
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    stepcompany: {
        fontFamily: 'OpenSansSemiBold'
    },
    stepdate: {
        fontFamily: 'OpenSansItalic',
        fontSize: '0.77rem'
    },
    cardtitle: {
        fontFamily: 'OpenSansSemiBold',
        fontSize: '1.1rem'
    },
    cardsubtitle: {
        fontFamily: 'OpenSansItalic',
        fontSize: '1rem'
    },
    carddescription: {
        fontFamily: "OpenSansRegular",
        marginTop: '0.5rem',
        fontStyle: "italic",
        fontWeight: 400,
        textAlign: 'justify',
    },
    hr: {
        border: '0.5px solid #adb5bd',
        marginTop: 0,
        marginBottom: 0,
    },
    chiptext: {
        opacity: 1,
        left: 0,
        transition: '0.6s',
        fontWeight: 500,
        fontSize: '0.6rem'
    },
    chip: {
        margin: theme.spacing(0.5),
        color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
    },
    stepbutton: {
        '&:active': {
            color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
            backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
        }
    }
}))

function ExperienceComponent(props) {
    const classes = ExperienceStyles(props);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleStep = (step) => () => {
        if (activeStep === step) {
            setActiveStep(-1);
        } else {
            setActiveStep(step);
        }
    };

    function renderSkills(skills) {
        const skillArray = [];
        skills.forEach(skill => {
            skillArray.push(
                <Chip size="small" avatar={<Avatar>{skill[0]}</Avatar>} label=
                    {
                        <Typography variant="button" className="text-center">
                            <div>
                                <span className={classes.chiptext}>{skill}</span>
                            </div>
                        </Typography>
                    } key={skill} variant="outlined" className={classes.chip} />
            );
        });
        return skillArray;
    }
    return (
        <Grow in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}>
            <div className='row' id="experience">
                <Card className={classes.root} elevation={4}>
                    <h4 className={classes.heading + ' text-center mt-3'}>EXPERIENCE</h4>
                    <div className=' col-12 col-lg-10 ml-auto mr-auto mt-3 mb-3 text-center'>
                        <Stepper nonLinear activeStep={activeStep} orientation='vertical' style={{padding: '4px'}}>
                            {steps.map((experience, index) => {
                                const buttonProps = {};
                                return (
                                    <Step key={experience.id}>
                                        <StepButton
                                            onClick={handleStep(index)}
                                            {...buttonProps}
                                            icon={activeStep === index ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                        >
                                            <span className={classes.stepdate}>{experience.startDate} to {experience.endDate ? experience.endDate : 'Present'}</span>
                                        </StepButton>
                                        <StepContent>
                                            <div className="row">
                                                <Card className={'text-left mb-3 col-11 col-lg-6 ml-auto mr-auto'} elevation={3}>
                                                    <CardContent>
                                                        <Typography variant='h5' className={classes.cardtitle} gutterBottom>
                                                            {experience.company}
                                                        </Typography>
                                                        <hr align='left' className={classes.hr} />
                                                        <Typography variant='h6' component='h6' color='textSecondary' className={classes.cardsubtitle}>
                                                            {experience.designation} - {experience.location}
                                                        </Typography>
                                                        <Typography variant='body2' component='p' className={classes.carddescription}>
                                                            {experience.description}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card className={'text-left mb-3 col-11 col-lg-5 ml-auto mr-auto'} elevation={3}>
                                                    <CardContent>
                                                        <Typography variant='h5' className={classes.cardtitle} gutterBottom>
                                                            Technology Stack
                                                        </Typography>
                                                        <hr align='left' className={classes.hr} style={{marginBottom: "5px"}}/>
                                                            {renderSkills(experience.skills)}
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </StepContent>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>
                </Card>
            </div>
        </Grow>
    )
}

export default ExperienceComponent;