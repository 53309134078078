const certs = [
    {
        id: "cert_9",
        name: "CNCF Certified Kubernetes Application Developer",
        imgURL: "/images/certs/cncf.jpg",
        imgGsURL: "/images/certs/cncf_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_11",
        name: "Google IT Automation With Python",
        imgURL: "/images/certs/gap.jpg",
        imgGsURL: "/images/certs/gap_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_10",
        name: "Azure Fundamentals",
        imgURL: "/images/certs/azure.png",
        imgGsURL: "/images/certs/azure_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_1",
        name: "E-Cornell Machine Learning Foundation Certification",
        imgURL: "/images/certs/ecornell.jpg",
        imgGsURL: "/images/certs/ecornell_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_2",
        name: "IBM Professional Data Science Certification",
        imgURL: "/images/certs/coursera.jpg",
        imgGsURL: "/images/certs/coursera_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_3",
        name: "Udacity Front-End Nano Degree",
        imgURL: "/images/certs/udacity.jpg",
        imgGsURL: "/images/certs/udacity_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_4",
        name: "Award For Excellence - Most Valuable Player",
        imgURL: "/images/certs/mvp.jpg",
        imgGsURL: "/images/certs/mvp_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_7",
        name: "Award For Excellence - Open Source Committer",
        imgURL: "/images/certs/osc.jpg",
        imgGsURL: "/images/certs/osc_gs.jpg",
        loaded: false,
        loadedUrl: null
    },    
    {
        id: "cert_8",
        name: "Award For Excellence - Thinker",
        imgURL: "/images/certs/t.jpg",
        imgGsURL: "/images/certs/t_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_5",
        name: "DNA Data Storage - A Review",
        imgURL: "/images/certs/rs1.jpg",
        imgGsURL: "/images/certs/rs1_gs.jpg",
        loaded: false,
        loadedUrl: null
    },
    {
        id: "cert_6",
        name: "Gold Medal- B.Tech Hons.",
        imgURL: "/images/certs/gold.jpg",
        imgGsURL: "/images/certs/gold_gs.jpg",
        loaded: false,
        loadedUrl: null
    }
]

export default certs;