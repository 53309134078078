import React, { useState } from 'react';
import { loadAvatar } from '../services/image.service';
import Skeleton from '@material-ui/lab/Skeleton';

function AvatarComponent(props) {
    const [avatarSrc, setAvatarSrc] = useState(null);
    const avatarURL = props.selectedtheme === 'light' ? '/images/avatar.jpg' : 'images/avatar_gs.jpg';
    loadAvatar(avatarURL).then(res => {
        const image = 'data:image.jpg;base64,' + Buffer.from(res.data, 'binary').toString('base64');
        setAvatarSrc(image);
    });

    if (avatarSrc) {
        return (
            <img src={avatarSrc} loading="lazy" alt="AVATAR" style={{ width: "100%", height: "100%", maxWidth: '125px', maxHeight: '125px', minWidth: '125px', minHeight: '125px' }} className="circle-avatar img-thumbnail" />
        );
    } else {
        return (
            <Skeleton variant="circle" className="img-thumbnail" width={125} height={125} style={{
                maxWidth: '125px', maxHeight: '125px', minWidth: '125px', minHeight: '125px'
            }} />
        );

    }
}

export default AvatarComponent;