import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Card } from '@material-ui/core';
import certs from '../data/certification.data';
import Grow from '@material-ui/core/Grow';
import { loadImage } from '../services/image.service';
import { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const AChievementStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    img: {
        height: '100%',
        display: 'block',
        width: '100%',
    },
    cardRoot: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px',
    },
    heading: {
        fontFamily: 'OpenSansSemiBold',
        letterSpacing: '10px'
    },
    subheading: {
        fontFamily: 'OpenSansRegular',
        letterSpacing: '1px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    toggleTheme: {
        // color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        // backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
    },
    imgWrapper: {
        maxHeight: '255px',
        overflowY: 'scroll'
    }
});

export default function AchievementsComponent(props) {
    const classes = AChievementStyles(props);
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = certs.length;
    const [loadedImages, UpdateImages] = useState({});
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderImage = (objs, theme) => {
        if (!loadedImages[objs.id + '_' + theme]) {
            loadImage(theme === 'light' ? objs.imgURL : objs.imgGsURL).then(res => {
                const data = 'data:image.jpg;base64,' + Buffer.from(res.data, 'binary').toString('base64');
                const obj = { ...loadedImages };
                obj[objs.id + '_' + theme] = data;
                UpdateImages(obj);
            });
            return (
                <Skeleton variant="rect" className="img-thumbnail" width={'100%'} height={255} style={{
                    maxWidth: '100%', maxHeight: '255px', minWidth: '100%', minHeight: '255px'
                }} />
            );
        } else {
            return (
                <img
                    className={classes.img}
                    src={loadedImages[objs.id + '_' + theme]}
                    alt={objs.name}
                />
            );
        }
    }

    return (
        <Grow in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}>

            <div className={classes.root + " row"} id="certifications">
                <Card className={classes.cardRoot} elevation={3}>
                    <h4 className={classes.heading + ' text-center mt-3'}>CERTIFICATIONS &amp; AWARDS</h4>
                    <Card className={classes.toggleTheme + ' col-12 col-sm-8 col-lg-5 ml-auto mr-auto mt-3 mb-3 text-center'} elevation={4}>
                        <Paper square elevation={0} className={classes.subheading + classes.toggleTheme + " text-center"}>
                            <h6 align="center" className={classes.subheading + " text-center"}>{certs[activeStep].name}</h6>
                        </Paper>
                        <div className={classes.imgWrapper}>

                            {
                                renderImage(certs[activeStep], props.selectedtheme)
                            }

                        </div>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="text"
                            style={{ backgroundColor: "inherit" }}
                            activeStep={activeStep}
                            className={classes.toggleTheme}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.toggleTheme}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.toggleTheme}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                </Button>
                            }
                        />
                    </Card>
                </Card>
            </div>
        </Grow>
    );
}
