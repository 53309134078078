import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Navs from '../data/nav.data';
import BootstrapTooltip from '../common/bootstraptooltip.common';

const SideNavStyles = makeStyles({
    root: {
        position: 'fixed',
        top: '40%',
        maxWidth: '100px',
        left: '-30px',
        zIndex: 1000,
        backgroundColor: 'transparent',
        outline: 'none',
        boxShadow: 'none',
        '@media (max-width: 768px)': {
            visibility: 'hidden'
        },
        '& ul': {
            listStyle: 'none',
            '& li': {
                color: props => props.selectedtheme === 'light' ? '#4b494c' : '#000',
                fontWeight: 'bold',
                marginTop: '1.5rem',
                opacity: 0.5,
                cursor: 'pointer',
                zIndex: 1000
            }
        },
    },
    sidehr: {
        border: '2px solid #000',
    }
});
function SideNavComponent(props) {
    const classes = SideNavStyles(props);
    const jumpToSection = (link) => {
        window.localStorage.setItem('active_link', link);
        props.onnavchange(link);
        return link;
    }

    const renderNav = (navs, activeNav) => {
        const navsArray = [];
        navs.forEach(nav => {
            navsArray.push(
                <li onClick={() => jumpToSection(nav.link)} key={nav.id}>
                    <BootstrapTooltip title={'Go To ' + nav.name + ' Section'}>
                        <div>
                            <hr align="left" className={classes.sidehr} style={{ width: nav.link === activeNav ? '60px' : '30px', opacity: nav.link === activeNav ? 1 : '0.3', marginTop: '2rem', height: nav.link === activeNav ? '1px' : '0px', backgroundColor: 'black' }} />
                            {
                                activeNav === nav.link && (
                                    <span style={{ paddingLeft: '2px', opacity: nav.link === activeNav ? 1 : 0.3 }}>{nav.name}</span>
                                )
                            }

                        </div>
                    </BootstrapTooltip>

                </li>
            );
        });
        return navsArray;
    }

    return (
        <Paper className={classes.root + ' sticky-nav'} id='sideNav'>
            <ul className='text-left'>
                {
                    renderNav(Navs, props.activeNav)
                }
            </ul>
        </Paper>
    );
}

export default SideNavComponent;