import React from 'react';
import AvatarComponent from './avatar.component';
import CallRounded from '@material-ui/icons/CallRounded';
import MailRounded from '@material-ui/icons/MailRounded';
import CodeRounded from '@material-ui/icons/CodeRounded';
import WorkRounded from '@material-ui/icons/WorkRounded';
import AcUnit from '@material-ui/icons/AcUnit';
import { makeStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

const HeaderStyles = makeStyles({
    root: {
    },
    ui: {
        listStyle: 'none',
        fontFamily: 'OpenSansBold',
        fontSize: '0.95rem'
    },
    a: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
})
function HeaderComponent(props) {
    const classes = HeaderStyles();
    return (
        <Slide in={true} direction="left" {...(true ? { timeout: 1000 } : {})}>

            <div className="row mt-3" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div className="col-4 col-md-2 text-center ml-auto mr-auto">
                    <AvatarComponent selectedtheme={props.selectedtheme}/>
                </div>
                <div className="col-12 col-md-5 align-header mt-2">
                    <h2 className="mt-2" style={{ fontFamily: 'OpenSansBold', fontSize: '2rem' }}>DIVESH PANWAR</h2>
                    <h4 className="mt-2" style={{ fontFamily: 'OpenSansBoldItalic', fontSize: '1rem' }}>SPECIALIST PROGRAMMER - INFOSYS LTD.</h4>
                    <h5 className="mt-2" style={{ fontFamily: 'OpenSansSemiBold', fontSize: '0.95rem' }}>2017 - Present</h5>
                </div>
                <div className="col-12 col-md-5 align-header-contact mt-3">
                    <ul className={classes.ui}>
                        <li>+91 9882887885&nbsp;<CallRounded /></li>
                        <li>divesh.panwar@gmail.com&nbsp;<MailRounded /></li>
                        <li><a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://github.com/diveshpanwar">git@/diveshpanwar</a>&nbsp;<CodeRounded /></li>
                        <li><a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/divesh-panwar-44272685/">linkedin@divesh-panwar-44272685</a>&nbsp;<WorkRounded /></li>
                        <li><a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/~divesh.panwar">npm@~divesh.panwar</a>&nbsp;<AcUnit /></li>
                    </ul>
                </div>
            </div>
        </Slide>)
}

export default HeaderComponent;