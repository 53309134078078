const rpData = [
    {
        id: "rp_1",
        name: "DNA Data Storage",
        date: "June, 2020",
        description: "This research paper reviews the DNA data storage process and the encoding techniques used in the field of DNA data storage. Current developments and research work done in this field have also been reviewed in this paper. This research paper has been published in IJSREM, Volumn 4, Issue 6, 2020.",
        link: "http://ijsrem.com/download/a-review-dna-data-storage/?wpdmdl=3676&masterkey=5ef9d89378cf7"
    }
];

export default rpData;