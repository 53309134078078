const experiences = [
    {
        id: "exp_1",
        company: "Infosys Limited",
        startDate: "01 Apr, 2019",
        present: true,
        location: 'Pune',
        designation: 'Specialist Programmer',
        description: 'I have developed various Business UI Applications and Widgets which are reusable, fast and scalable. I am also responsible for UI/UX of the applications being developed along with their architecture planning and deciding their technology stack. One of the popular application I have created and architected NIA CA Search, Custom UI - DocAi, Comfort Letter Automation - PWC. Along with this my job role includes Open Source Contributions, knowledge sharing, publishing research papers, experimenting with latest/new technologies, project management and motivating teams.',
        skills: ['HTML', 'CSS', 'Bootstrap', 'Jquery', 'Material', 'Angular 2+', 'Flask', 'JS', 'Python', 'Redux', 'MySql', 'Typescript', 'Shell Scripting', 'Docker', 'Micro UI', 'React', 'Spacy', 'Pandas', 'Apache NiFi', 'Redis Queue', 'Seaborn', 'Apache Kafka', 'OCR', 'MatPlotLib', 'Scipy', 'Kubernetes']
    },
    {
        id: "exp_2",
        company: "Infosys Limited",
        startDate: "09 Oct, 2017",
        endDate: '31 Mar, 2019',
        present: false,
        location: 'Mysore',
        designation: 'Systems Engineer',
        description: 'I developed micro-ui for popular banks and other clients. I was responsible for creating fast, reusable and scalable UI components and widgets can can be launched from any platform.',
        skills: ['HTML', 'CSS', 'Bootstrap', 'Material', 'Angular 2+', 'Python', 'Redux', 'JS', 'Jquery', 'MySql', 'Typescript', 'Shell Scripting', 'Docker', 'Micro UI', 'C#']
    },
    {
        id: "exp_3",
        company: "Busibud Solutions",
        startDate: "01 Aug, 2017",
        endDate: '01 Oct, 2017',
        present: false,
        location: 'Mohali',
        designation: 'Software Engineer Trainee',
        description: 'As a trainee I have worked on FamBay, and WordPress plugin development along with Laravel.',
        skills: ['Laravel', 'Docker', 'MongoDB', 'MySQL', 'WordPress', 'HTML', 'CSS', 'JS', 'Jquery', 'Bootstrap']
    },
    {
        id: "exp_4",
        company: "Freelancer",
        startDate: "01 Mar, 2013",
        endDate: '31 Jul, 2017',
        present: false,
        location: 'Shimla',
        designation: 'Full Stack Developer',
        description: 'I have worked on several projects icluding few for state hospital H.P, local institues and H.P University.',
        skills: ['PHP', 'Laravel', 'HTML', 'CSS', 'Bootstrap', 'Linux', 'Git', 'Java', 'JS', 'Jquery', 'MySql', 'WordPress', 'Shell Scripting', 'JSP', 'C', 'C++']
    }
];

export default experiences;