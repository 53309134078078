import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const FooterStyles = makeStyles({
    root: {
        backgroundColor: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        color: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
    },
    typo1: {
        fontSize: "1rem",
        fontFamily: "OpenSansSemiBold",
        marginTop: "20px"
    },
    typo2: {
        fontSize: "0.8rem",
        fontFamily: "OpenSansItalic",
        marginTop: "10px",
        marginBottom: "10px"
    },
    typo3: {
        fontSize: "0.8rem",
        fontFamily: "OpenSansItalic",
        marginTop: "10px",
        marginBottom: "20px"
    },
    a: {
        color: "inherit",
        '&:hover': {
            color: "inherit"
        },
        '&:visited': {
            color: "inherit"
        }
    },
});

function FooterComponent(props) {
    const classes = FooterStyles(props);
    return (
        <div className={classes.root + " row mt-3"}>
            <Paper elevation={4} variant="elevation" className={classes.root + " col-12 text-center"}>
                <Typography variant="h5" className={classes.typo1}>
                    &copy; Divesh Panwar {String.fromCodePoint('0x1F525')}
                </Typography>
                <Typography variant="h5" className={classes.typo2}>
                    {String.fromCodePoint('0x1F496')} CREATED WITH LOVE {String.fromCodePoint('0x1F496')}
                </Typography>
                <Typography className={classes.typo3}>
                    <a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/divesh-panwar-44272685/"><LinkedInIcon /></a>
                    &nbsp;
                    <a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://github.com/diveshpanwar"><GitHubIcon /></a>
                    &nbsp;
                    <a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/ahamMrityuAsmi/"><InstagramIcon /></a>
                    &nbsp;
                    <a className={classes.a} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/aham_mrityu_asmi/"><FacebookIcon /></a>
                </Typography>
            </Paper>
        </div>
    );
}

export default FooterComponent;