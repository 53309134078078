import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LanguageIcon from '@material-ui/icons/Language';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import projects from '../data/projects.data';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const OSStyles = makeStyles({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px',
    },
    heading: {
        fontFamily: 'OpenSansSemiBold',
        letterSpacing: '10px',
    },
    heading2: {
        fontFamily: 'OpenSansSemiBold',
        textTransform: "uppercase",
        fontWeight: 200,
        marginTop: '6px',
        fontSize: '1rem',
        '@media(max-width: 768px)': {
            fontSize: '0.8rem'
        }
    },
    heading3: {
        fontFamily: 'OpenSansRegular',
        textTransform: "uppercase",
        fontWeight: 200,
        marginTop: '6px',
        fontSize: '0.8rem',
        '@media(max-width: 768px)': {
            fontSize: '0.75rem'
        }
    },

    subheading: {
        fontFamily: 'OpenSansBold',
        letterSpacing: '1px',
    },
    button: {
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    hr: {
        border: '0.5px solid #adb5bd',
        marginTop: 0,
        marginBottom: 0,
    },
    stepbutton: {
        '&:active': {
            color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
            backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
        }
    },
    listtext: {
        fontFamily: "OpenSansLight"
    },
    paper: {
        padding: '6px 16px',
        minHeight: '50px',
    },
    dialogButton: {

    },
    secondaryTail: {
        backgroundColor: '#333',
    },
    dotIcon: {
        backgroundColor: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        color: props => props.selectedtheme === 'dark' ? '#4b494c' : '#fff',
    },
    oppositeContent: {
        paddingTop: '20px',
    },
    dialog: {
        // minWidth: "90%"
    },
    customHr: {
        border: "1px solid #333",
        backgroundColor: "#333",
        margin: 0,
        marginLeft: "auto",
        marginRight: "auto",
        width: "5%"
    },
    a: {
        color: "inherit",
        '&:hover': {
            color: "inherit"
        },
        '&:visited': {
            color: "inherit"
        }
    },
})


function ProjectsComponent(props) {
    const classes = OSStyles(props);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});
    const [loaded, setLoaded] = React.useState(false);
    const handleClickOpen = (osc) => {
        console.log(osc);
        setData(osc);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoaded(false);
    };

    const handleLoading = () => {
        setLoaded(true);
    }

    const renderTimeline = (rps) => {
        const osArray = [];
        rps.forEach(rp => {
            osArray.push(
                <TimelineItem key={rp.id}>
                    <TimelineOppositeContent className={classes.oppositeContent}>
                        <Typography variant="body2" color="textSecondary">
                            {rp.date}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot className={classes.dotIcon}>
                            <LanguageIcon />
                        </TimelineDot>
                        <TimelineConnector className={classes.secondaryTail} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h6" component="h6" className={classes.heading2} align="center">
                                {rp.name}
                                <br />
                                <Typography className={classes.heading3}> {rp.subtitle}</Typography>
                                <IconButton onClick={() => handleClickOpen(rp)}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            );
        });
        return osArray;
    }

    return (
        <Grow in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}>
            <div className='row' id="projects">
                <Card className={classes.root} elevation={4}>
                    <h4 className={classes.heading + ' text-center mt-3'}>PROJECTS</h4>
                    <Timeline align="alternate" className="col-12 col-lg-10 ml-auto mr-auto mt-3 mb-3 text-center">
                        {renderTimeline(projects)}
                    </Timeline>
                </Card>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    fullScreen={true}
                    onClose={handleClose}
                    aria-labelledby={data.name}
                    className={classes.dialog}
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" className="text-center" style={{ fontFamily: "OpenSansLight", textTransform: "uppercase" }}>{data.name}</DialogTitle>
                    <hr className={classes.customHr} align="center" />
                    <DialogContent>
                        {!loaded && (

                            <Skeleton style={{ width: '100%', height: '80vh', maxHeight: '100%' }}></Skeleton>
                        )
                        }
                        <iframe src={data.link} title={data.name} style={{ width: '100%', height: '80vh', maxHeight: '100%', display: loaded ? "block" : 'none' }} onLoad={handleLoading} />
                    </DialogContent>
                    <DialogActions className="text-center" style={{ display: 'block' }}>
                        <Fab onClick={handleClose} color="primary" variant="extended" size="medium">
                            <a href={data.link} className={classes.a} target="_blank" rel="noopener noreferrer" ><LinkIcon /> VISIT</a>
                        </Fab>
                        <Fab variant="extended" onClick={handleClose} size="medium">
                            <CloseIcon />
                            CLOSE
                        </Fab>
                    </DialogActions>
                </Dialog>
            </div>
        </Grow>
    );
}


export default ProjectsComponent;