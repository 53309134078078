import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grow from '@material-ui/core/Grow';
import Skills from '../data/skills.data';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const SkillStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px',
    },
    heading: {
        fontFamily: 'OpenSansSemiBold',
        letterSpacing: '10px'
    },
    subheading: {
        fontFamily: 'OpenSansBold',
        letterSpacing: '1px',
    },
    chiplabel: {
        opacity: 0,
        right: '-100px'
    },
    chiptext: {
        opacity: 1,
        left: 0,
        transition: '0.6s',
        position: "absolute"
    },
    chip: {
        margin: theme.spacing(0.5),
        color: props => props.selectedtheme === 'light' ? '#4b494c' : '#fff',
        backgroundColor: props => props.selectedtheme === 'light' ? '#fff' : '#4b494c',
        '&:hover': {
            color: props => props.selectedtheme === 'dark' ? '#4b494c' : '#fff',
            backgroundColor: props => props.selectedtheme === 'dark' ? '#fff' : '#4b494c',
            '& $chiplabel': {
                opacity: 1,
                transition: '0.5s',
                right: '33px',
                display: "content"
            },
            '& $chiptext': {
                opacity: 0,
                left: '-100px'
            }
        }
    },
}))

function SkillsComponent(props) {
    const classes = SkillStyles(props);

    function renderSkills(skills) {
        const skillArray = [];
        skills.forEach(skill => {
            skillArray.push(
                <Chip size="medium" avatar={<Avatar className={"font-weight-bold"}>{skill.name[0]}</Avatar>} label=
                    {
                        <Typography variant="button" className="text-center">
                            <div style={{ position: "relative", width: "85px" }}>
                                <span className={classes.chiptext}>{skill.name}</span>
                                <span className={classes.chiplabel}>{skill.level}%</span>
                            </div>
                        </Typography>
                    } key={skill.name} variant="outlined" className={classes.chip} />
            );
        });
        return skillArray;
    }
    return (
        <Grow in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}>
            <div className="row" id="skills">
                <Card className={classes.root} elevation={4}>
                    <h4 className={classes.heading + " text-center mt-3"}>SKILLS</h4>
                    <div className="mt-3 mb-3 text-center">
                        {renderSkills(Skills)}
                    </div>
                </Card>
            </div>
        </Grow>
    )
}

export default SkillsComponent;