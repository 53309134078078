import React from 'react';
import { makeStyles } from '@material-ui/core';

const HrStyles = makeStyles({
    root: {
        border: props => props.selectedtheme === 'light' ? '1px solid #333' : '1px solid #fff',
        backgroundColor: props => props.selectedtheme === 'light' ? '#333' : '#fff',
        width: props => props.width ? props.width : '5%',
    }
})

function HrComponent(props) {
    const classes = HrStyles(props);
    return <hr className={classes.root + ' mt-3 mb-3'} />
}

export default HrComponent;