const certs = [
    {
        id: "proj_1",
        name: "Ekart",
        subtitle: "E-Commerce Website Blueprint",
        link: "https://showcase.thenoobsbook.dev/ekart/",
        date: "August, 2019"
    },
    {
        id: "proj_3",
        name: "Insta-Downloader",
        subtitle: "Download Instagram Images/Videos",
        link: "https://showcase.thenoobsbook.dev/insta-downloader/",
        date: "July, 2019"
    },
    {
        id: "proj_4",
        name: "Image Optimizer",
        subtitle: "Optimize Images Online",
        link: "https://showcase.thenoobsbook.dev/imgopti/",
        date: "March, 2018"
    },
    {
        id: "proj_5",
        name: "Arcade Game",
        subtitle: "Game Built Using Canvas API",
        link: "https://showcase.thenoobsbook.dev/arcadegame/",
        date: "August, 2017"
    },
    {
        id: "proj_2",
        name: "UIIT Webiste",
        subtitle: "Institute's Website 2015 - 2019",
        link: "https://showcase.thenoobsbook.dev/uiit/",
        date: "Jan, 2015"
    }
]

export default certs;